@import '@lumapps/lumx/scss/variables.scss';

.tutorial {
    /**
    * If you ever change these values, please do the same on the constants.ts file
    * in this package
    */
    &__slides {
        @include mq($until: small) {
            width: 300px;
        }

        @include mq($from: small) {
            width: 300px;
        }

        @include mq($from: mobile) {
            width: 400px;
        }

        @include mq($from: desktop) {
            width: 800px;
        }
    }
}
