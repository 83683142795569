@import '../../../scss/variables.scss';

.lightshow-slide {
    background-color: lumx-color-variant('primary', 'N');
    color: lumx-color-variant('light', 'N');
    height: fit-content;

    &__caption {
        padding: $lumx-spacing-unit-big;
        max-height: 400px;
        overflow: scroll;
    }
}
