@import '../../../scss/variables.scss';

.lightshow {
    &__slides {
        margin: 0 auto;
    }

    &__slides .lumx-slideshow__wrapper {
        align-items: center;
    }

    &__controls-container {
        justify-content: center;
        margin-top: $lumx-spacing-unit-big;
    }

    /**
     * The DS unfortunately does not change the lightbox position
     * in responsive. This fixes that issue
     */
    .lumx-lightbox__wrapper {
        @include mq($until: mobile) {
            top: 32px;
            right: 32px;
            bottom: 32px;
            left: 32px;
        }
    }
}
